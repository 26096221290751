<template>
  <vx-card class="grid-view-item mb-base overflow-hidden">
    <template slot="no-body">

      <!-- ITEM IMAGE -->
      <div class="item-img-container bg-white h-32 flex items-center justify-center mb-4">
        <img :src="baseUrl+'/img/file_icon/'+ getExt(file.storage_name)" :alt="file.attachment_name" width="100px" class="grid-view-img px-4">
      </div>
      <div class="item-details px-4">
        <div>
          <h6 class="truncate font-semibold mb-1">{{file.attachment_name}}</h6>
          <p class="item-description truncate text-sm">{{file.created_at | formatDate}}</p>
        </div>
      </div>
      <div class="flex flex-wrap">

        <!-- PRIMARY BUTTON: ADD TO WISH LIST -->
        <div @click="deleteFile(file.storage_name)"
          class="item-view-primary-action-btn bg-danger p-3 text-white flex flex-grow items-center justify-center cursor-pointer">
          <feather-icon icon="TrashIcon" :svgClasses="['fill-current h-4 w-4']" />

          <span class="text-sm font-semibold ml-2" @click="deleteFile(file.storage_name)">Delete</span>
        </div>

        <!-- SECONDARY BUTTON: ADD-TO/VIEW-IN CART -->
        <div
          class="item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer"
          @click="downloadFile(file.storage_name, file.attachment_name)">
          <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4" />
          <span class="text-sm font-semibold ml-2">Download</span>
        </div>
      </div>
    </template>
  </vx-card>
</template>

<script>
  export default {
    props:{
      file:{
        require:true
      }
    },
    data() {
      return {
        baseUrl: process.env.VUE_APP_BASE_URL,
        type:{
          'jpg':'jpg.png',
          'jpeg':'jpg.png',
          'png':'png.png',
          'pdf':'pdf.png',
          'doc':'doc.png',
          'docx':'doc.png',
          'csv':'csv.png',
          'xlxs':'xls.png',
          'txt':'txt.png',
          'mp4':'mp4.png',
          'ai':'ai.png',
          'mp3':'mp3.png',
          'zip':'zip.png',


        }
      }
    },
    methods:{
      getExt(name){
        if (this.type[name.split('.').pop()]){
          return this.type[name.split('.').pop()];
        }
        else{
          return 'file.png'
        }
      },
      downloadFile(file, name){
        this.axios.get('/download/'+file, {
          responseType: 'blob',
        }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      },
      deleteFile(file){
        this.axios.delete('/delete/'+file).then(()=>{
          this.$emit('deleted');
        });
      }
    }
  }
</script>

