<template>
  <div>
    <vx-card class="mb-5">
      <div  class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          Expense Detail
          <table style="width:100%" class="border-collapse">
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light">Type</td>
              <td class="p-2 border border-solid d-theme-border-grey-light">{{transaction.type == 'gexpense'? 'General Expense': transaction.type}}</td>
            </tr>
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light">Date</td>
              <td class="p-2 border border-solid d-theme-border-grey-light">{{transaction.created_at | formatDate}}</td>
            </tr>
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light">Detail</td>
              <td class="p-2 border border-solid d-theme-border-grey-light">{{transaction.detail}}</td>
            </tr>
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light">Amount</td>
              <td class="p-2 border border-solid d-theme-border-grey-light">{{transaction.amount | currency}}</td>
            </tr>
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light">Created By</td>
              <td class="p-2 border border-solid d-theme-border-grey-light">{{transaction.created_by.name}}</td>
            </tr>
          </table>
        </div>
        <div class="vx-col lg:w-1/2 w-full">
          Upload New
          <vs-upload :key="uploadKey" @on-success="uploadSuccess" :data="{transaction_id:$route.params.id}" @on-error="uploadFailed" fileName="attachment[]" :action="baseUrl+'/api/upload'" :automatic="true" :headers="{Authorization:'Bearer '+$auth.token()}"    multiple text="Attachments" :show-upload-button="true" />
        </div>
      </div>
    </vx-card>
    <div class="items-grid-view vx-row match-height" v-if="transaction.attachments">
      <div class="vx-col lg:w-1/5 sm:w-1/2 w-full" v-for="attachment in transaction.attachments">
        <file-view @deleted="update" :file="attachment"></file-view>
      </div>
    </div>

    <vs-alert v-else active="true">
      No attachment
    </vs-alert>
  </div>


</template>
<script>
  import FileVIew from "../components/FileView"
  export default {
    data(){
      return {
        transaction:'',
        baseUrl:process.env.VUE_APP_BASE_URL,
        uploadKey:0,
      }
    },
    methods:{
      update(){
        this.axios.get('transaction/'+this.$route.params.id)
          .then(res => {
            this.transaction = res.data;
          });
      },
      uploadSuccess(event){
        this.update();
        this.uploadKey+=1;
      },
      uploadFailed(event){
        console.log(event);
      },
    },
    mounted(){
      this.update();
    },

    components:{
     'file-view':FileVIew
    }
  }
</script>
